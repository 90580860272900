const Occasion = {
  Birthday: 'Birthday',
  ChristmasHanukkah: 'Christmas/Hanukkah',
  ValentinesDay: 'Valentine\'s Day',
  MothersDay: 'Mother\'s Day',
  FathersDay: 'Father\'s Day',
  Anniversary: 'Anniversary',
  Graduations: 'Graduations',
  BabyShowers: 'Baby showers',
  BridalShowers: 'Bridal showers',
  Weddings: 'Weddings',
  Housewarmings: 'Housewarmings',
  RetirementParty: 'Retirement party',
  NewJob: 'New job',
  Promotions: 'Promotions',
  ThankYou: 'Thank you',
  Sympathy: 'Sympathy',
  GetWellSoon: 'Get well soon',
  Congratulations: 'Congratulations',
  JustBecause: 'Just because'
};

export {Occasion}