import './App.css';
import './wizard.css';
import 'bootstrap/dist/css/bootstrap.css';

import React, {useState, useEffect} from 'react';
import {Tab, Nav} from 'react-bootstrap';
import Particles from 'react-particles';
import {loadConfettiPreset} from 'tsparticles-preset-confetti';
import {AgeGender, EAgeGender} from './AgeGender';
import {Relationship} from './Relationship';
import {Occasion} from './Occasion';
import {Price} from './Price';

// import TagManager from 'react-gtm-module'

const App = () => {

    const [slide, setSlide] = useState('age_gender');
    const [isLast, setIsLast] = useState(false);
    const [isFirst, setIsFirst] = useState(true);
    const [isResults, setIsResults] = useState(false);
    const [ageGender, setAgeGender] = useState<string>(EAgeGender.YOUNG_WOMAN);
    const [relationship, setRelationship] = useState(Relationship.Girlfriend);
    const [occasion, setOccasion] = useState(Occasion.Birthday);
    const [interests, setInterests] = useState('');
    const [price, setPrice] = useState(Price.From20To50);

    const [results, setResults] = useState([]);

    const [isConfetti, setIsConfetti] = useState(false);

    const tabs = ['age_gender', 'relationship', 'occasion', 'interests', 'price', 'results'];

    useEffect(() => {
        // TagManager.trackEvent('Gift Idea Search', {
        //     'age_gender': ageGender,
        //     'relationship': relationship,
        //     'occasion': occasion,
        //     'interests': interests
        // });

    }, [ageGender, relationship, occasion, interests]);


    const TsParticles = () => {
        return (
            <div className='container'>
                {/*<button onClick={() => setIsConfetti(true)} disabled={isConfetti}>
        Render confetti
      </button>*/}
                {isConfetti && <Particles id='tsparticles' init={loadConfettiPreset} options={{preset: 'confetti'}}/>}
            </div>
        );
    };

    function AgeGenderDropdown() {
        // const options = Object.values(AgeGender)
        const options = Object.entries(AgeGender).map(([key, value]) => ({
            value: key,
            label: value.name,
        }));
        return (
            <select name="age_gender"
                    className="form-select"
                    value={ageGender}
                    onChange={(e) => setAgeGender(e.currentTarget.value)}>
                {options.map((option) => (
                    <option value={option.value} key={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        );

    }

    function RelationshipDropdown() {
        const options = Object.values(Relationship);
        return (
            <select name="relationship"
                    className="form-select"
                    value={relationship}
                    onChange={(e) => setRelationship(e.target.value)}>
                {options.map((value) => (
                    <option value={value} key={value}>
                        {value}
                    </option>
                ))}
            </select>
        )
    }

    function OccasionDropdown() {
        const options = Object.values(Occasion);
        return (
            <select name="occasion"
                    className="form-select"
                    value={occasion}
                    onChange={(e) => setOccasion(e.target.value)}>
                {options.map((value) => (
                    <option value={value} key={value}>
                        {value}
                    </option>
                ))}
            </select>
        )
    }

    function PriceDropdown() {
        const options = Object.values(Price);
        return (
            <select name="price"
                    className="form-select"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}>
                {options.map((value) => (
                    <option value={value} key={value}>
                        {value}
                    </option>
                ))}
            </select>
        );
    }

    function linkClick(event: React.MouseEvent<HTMLAnchorElement>) {
        event.preventDefault()
        // @ts-ignore
        window.gtag_report_conversion();
        window.open(event.currentTarget.href);
    }


    function Result(name: string, i: number) {
        const name_encoded = encodeURIComponent(name);
        const link = `https://www.amazon.com/s?k=${name_encoded}&crid=3OIXTO5Y7FAAF&sprefix=%2Caps%2C106&linkCode=ll2&tag=askai-20&linkId=3f795dd0b2776bbd7f27a1e77d17c762&language=en_US&ref_=as_li_ss_tl`;


        return <li className="list-group-item" key={"result_li_" + i}>
            <a key={"result_a_" + i} href={link} onClick={linkClick}>{name}</a>
        </li>
    }

    function Next(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let idx = tabs.indexOf(slide);
        if (idx !== tabs.length - 1)
            handleTabSelect(tabs[idx + 1]);
        return false;
    }

    function Prev(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        let idx = tabs.indexOf(slide);
        if (idx !== 0) {
            handleTabSelect(tabs[idx - 1]);
        }

        return false;
    }


    function handleTabSelect(name: string | null) {
        if (!name)
            return
        setSlide(name);


        setIsFirst(name === 'age_gender');
        setIsLast(name === 'price');
        setIsResults(name === 'results');

        if (name === 'results')
            setIsConfetti(true);
    }

    function search(e: React.MouseEvent) {
        e.preventDefault();
        const body = {
            age_gender: AgeGender[ageGender as EAgeGender].name,
            relationship: relationship,
            occasion: occasion,
            interests: interests,
            price: price,
        };
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(body)
        };
        fetch((process.env.REACT_APP_API_URL || '') + '/api/search', requestOptions)
            .then(response => response.json())
            .then(data => {
                    setResults(data.results);
                    handleTabSelect('results');
                }
            );

        return false;
    }

    return (
        <form className="search-form m-lg-5 m-0">
            <h2>Gift Idea Search</h2>
            <i>Powered by ChatGPT</i>
            <br/>
            <br/>
            <Tab.Container defaultActiveKey="age_gender" activeKey={slide} onSelect={handleTabSelect}>
                <Nav variant="pills" className="flex-row tab-status">
                    <Nav.Item as="div">
                        <Nav.Link as="span" eventKey="age_gender" className="tab">1</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as="span" eventKey="relationship" className="tab">2</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as="span" eventKey="occasion" className="tab">3</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as="span" eventKey="interests" className="tab">4</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as="span" eventKey="price" className="tab">5</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link as="span" eventKey="results" className="tab"
                                  disabled={results.length > 0}>6</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="age_gender">
                        <div role="tabpanel" id="age_gender" className="tabpanel">
                            <h3>Who is this gift for?</h3>
                            <AgeGenderDropdown></AgeGenderDropdown>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="relationship">
                        <div role="tabpanel" id="color" className="tabpanel">
                            <h3>Who is your...</h3>
                            <RelationshipDropdown></RelationshipDropdown>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="occasion">
                        <div role="tabpanel" id="color" className="tabpanel">
                            <h3>What's the occasion?</h3>
                            <OccasionDropdown></OccasionDropdown>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="interests">
                        <div role="tabpanel" id="occupation" className="tabpanel">
                            <h3>{AgeGender[ageGender as EAgeGender].gender === 'F' ? 'Her' : 'His'}&nbsp;hobbies and interests
                            </h3>
                            <textarea name="interests" id="" cols={30} rows={2} className="form-input"
                                      value={interests} onInput={e => setInterests(e.currentTarget.value)}
                                      placeholder="Examples: yoga, travel, reading"></textarea>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="price">
                        <div role="tabpanel" id="price" className="tabpanel">
                            <h3>Select a price range</h3>
                            <PriceDropdown></PriceDropdown>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="results">
                        <div role="tabpanel" id="results" className="tabpanel">
                            <h3>Results</h3>
                            <ol className="list-group">
                                {results.map((result, i) => {
                                    return Result(result, i)
                                })

                                }

                            </ol>
                            <TsParticles></TsParticles>

                        </div>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <div className="pagination">
                {isFirst ? null : <button className="btn" id="prev" onClick={Prev}>Back</button>}
                {isResults ? null :
                    isLast ?
                        <button className="btn btn-submit" id="submit" onClick={search}>Search</button>
                        :
                        <button className="btn" id="next" onClick={Next}>Continue</button>
                }
            </div>
        </form>
    );
}

export default App;