const Price = {
    Any: "Any",
    Under10: "Under $10",
    From10To20: "$10-$20",
    From20To50: "$20-$50",
    From50To100: "$50-$100",
    From100To300: "$100-$300",
    From300To1000: "$300-$1000",
    From1000: "$1000 and up"
};

export {Price};
