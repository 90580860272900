const Relationship = {
    Girlfriend: 'Girlfriend',
    Boyfriend: 'Boyfriend',
    SpouseOrPartner: 'Spouse or partner',
    Parent: 'Parent',
    Sibling: 'Sibling',
    Grandparent: 'Grandparent',
    AuntUncle: 'Aunt/uncle',
    Cousin: 'Cousin',
    NieceAndNephew: 'Niece and nephew',
    InLaw: 'In-law',
    Child: 'Child',
    Godchild: 'Godchild',
    BestFriend: 'Best friend',
    CoWorker: 'Co-worker',
    BossOrSupervisor: 'Boss or supervisor',
    MentorOrTeacher: 'Mentor or teacher',
    Neighbor: 'Neighbor',
};

export {Relationship}