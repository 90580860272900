export enum EAgeGroup {
  BABY = 0,
  LITTLE = 1,
  TEEN = 2,
  YOUNG = 3,
  MIDAGE = 4,
  OLD = 5,
}

export enum EAgeGender {
  BABY_GIRL = 'BABY_GIRL',
  BABY_BOY = 'BABY_BOY',
  LITTLE_GIRL = 'LITTLE_GIRL',
  LITTLE_BOY = 'LITTLE_BOY',
  TEEN_GIRL = 'TEEN_GIRL',
  TEEN_BOY = 'TEEN_BOY',
  YOUNG_WOMAN = 'YOUNG_WOMAN',
  YOUNG_MAN = 'YOUNG_MAN',
  MIDAGE_WOMAN = 'MIDAGE_WOMAN',
  MIDAGE_MAN = 'MIDAGE_MAN',
  OLD_WOMAN = 'OLD_WOMAN',
  OLD_MAN = 'OLD_MAN',
}

export const AgeGender: Record<EAgeGender, {
  name: string;
  ageGroup: EAgeGroup;
  gender: string;
}> = {
  BABY_GIRL: {
    name: 'Baby girl',
    ageGroup: EAgeGroup.BABY,
    gender: 'F',
  },
  BABY_BOY: {
    name: 'Baby boy',
    ageGroup: EAgeGroup.BABY,
    gender: 'M',
  },

  LITTLE_GIRL: {
    name: 'Little girl',
    ageGroup: EAgeGroup.LITTLE,
    gender: 'F',
  },
  LITTLE_BOY: {
    name: 'Little boy',
    ageGroup: EAgeGroup.LITTLE,
    gender: 'M',
  },

  TEEN_GIRL: {
    name: 'Teenage girl',
    ageGroup: EAgeGroup.TEEN,
    gender: 'F',
  },
  TEEN_BOY: {
    name: 'Teenage boy',
    ageGroup: EAgeGroup.TEEN,
    gender: 'M',
  },

  YOUNG_WOMAN: {
    name: 'Young woman',
    ageGroup: EAgeGroup.YOUNG,
    gender: 'F',
  },
  YOUNG_MAN: {
    name: 'Young man',
    ageGroup: EAgeGroup.YOUNG,
    gender: 'M',
  },

  MIDAGE_WOMAN: {
    name: 'Middle-aged woman',
    ageGroup: EAgeGroup.MIDAGE,
    gender: 'F',
  },
  MIDAGE_MAN: {
    name: 'Middle-aged man',
    ageGroup: EAgeGroup.MIDAGE,
    gender: 'M',
  },

  OLD_WOMAN: {
    name: 'Elderly lady',
    ageGroup: EAgeGroup.OLD,
    gender: 'F',
  },
  OLD_MAN: {
    name: 'Elderly gentleman',
    ageGroup: EAgeGroup.OLD,
    gender: 'F',
  },
};